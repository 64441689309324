





















































































 import { Notify,Toast } from "vant"
 import HeadTop from "@/components/ShareComponent/HeadTop.vue"
 import List from "@/components/ShareComponent/PullDownUp.vue"
 import FooterBottom from "@/components/ShareComponent/ListBottom.vue"
 import Stroage from "@/util/Storage"
 import { AllHelpUpData } from "@/Api/Help/interfaceType"
 import { MyHelpItem } from "@/Type/index"
 import { UserType } from "@/Api/Basics/InterfaceType"
 import { IndexStore } from "@/store/modules/Index"
 import { HelpStore } from "@/store/modules/Help/Help"
 import { MyHelp as MyUpHelp,RemoveHelp } from "@/Api/Help/index"
 import { Vue,Component, Watch } from "vue-property-decorator"
 import ZoomPage from "@/impView/PageSafety";

 interface MyHelpType<T=string>{
     loadingShow :boolean
     userId:T|number;
     token:T;
     BackIcon:T;
     defaultIcon:T;
     sexIcon:T;
     sex1Icon:T;
     UserInfo:UserType
     PullDown:boolean;
     StopUp:boolean;
     UpDown:boolean;
     UpData:AllHelpUpData
     List:Array<MyHelpItem>
     ScrollNum:number;

     initData():void;
     handleBack():void;
     handleToPathSuc(data:MyHelpItem):void;
     handleRemoveBtn(data:MyHelpItem,e:any):void;
     handlePullDown(data?:any):void;
     handleUpDown(data?:any):void;
     handleUpData(bool?:any):void;
     handleFilterTime(time:string):void;
     handleFitlerDay(time:string):void;
     handleError(data:string):void;
 }

 @Component({ name:"MyHelp",components:{ List,FooterBottom,HeadTop } })
 export default class MyHelp extends ZoomPage implements MyHelpType{
    loadingShow = true
    userId:string|number = ""
    token = ""
    BackIcon = require("$icon/Basics/GoBack.png");
    defaultIcon = require("$icon/Basics/default.png");
    sexIcon = require("$icon/Basics/sex.png");
    sex1Icon = require("$icon/Basics/sex1.png");
    UserInfo:UserType = {}
    PullDown = false
    StopUp = false
    UpDown = false
    UpData:AllHelpUpData = {
        pageNo:1,
        pageSize:15
    }
    List:Array<MyHelpItem> = []
    ScrollNum = 0;

    handleBack(){
        this.$router.go(-1)
    }

    mounted() {
        this.loadingShow = false
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")
        try{
            this.UserInfo = JSON.parse( Stroage.GetData_ && Stroage.GetData_("UserData") )
        }catch(cat){
            new Error( cat )
        }
        this.initData()
    }

    initData(){
        this.UpData = HelpStore.GetHelpMyUpData
        if( HelpStore.GetHelpMyList.length ){
            this.List = HelpStore.GetHelpMyList
            let time = setTimeout(()=>{
                this.ScrollNum = HelpStore.GetHelpScroll.MyHelpScroll
                clearTimeout( time )
            },100)
        }else{
            this.handleUpData(true)
        }
    }

    handleToPathSuc(data:MyHelpItem){
        let ScrollData = HelpStore.GetHelpScroll
        let Num = IndexStore.GetScrollNum
        ScrollData.MyHelpScroll = Num
        HelpStore.SetHelpScroll( ScrollData )
        this.$router.push({
            path:"/helpSuc",
            query:{
                id:data.id as string
            }
        })
    }

    handleRemoveBtn(data:MyHelpItem,e:any){
        this.loadingShow = true
        RemoveHelp({
            userId:this.userId,
            token:this.token
        },{
            id:data.id as string
        }).then(res=>{
            this.loadingShow = false
            if( res.message.code === "200" ){
                Toast({
                    message:"删除成功",
                    icon:"none",
                    className:"ToastClassName"
                })
            }else{
                this.handleError( res.message.msg )
            }
        })
        // e.preventDefault();
        // e.stopPropagation();
    }

    handlePullDown(data?:any){
        this.PullDown = false
        this.StopUp = false
        this.UpData.pageNo = 1
        MyUpHelp({
            userId:this.userId,
            token:this.token
        },this.UpData).then(res=>{
            this.PullDown = true
            if( res.message.code === "200" ){
                if( res.data.length < this.UpData.pageSize ){
                    this.StopUp = true
                }
                this.List = res.data
            }else{
                this.handleError( res.message.msg )
            }
        })
    }

    handleUpDown(data?:any){
        if( this.StopUp )return;
        this.UpData.pageNo += 1
        this.handleUpData()
    }

    handleUpData(bool?:any){
        this.UpDown = true
        MyUpHelp({
            userId:this.userId,
            token:this.token
        },this.UpData).then(res=>{
            console.log( res )
            this.loadingShow = false
            this.UpDown = false
            if( res.message.code === "200" ){
                if( res.data.length < this.UpData.pageSize ){
                    this.StopUp = true
                }
                if( bool ){
                    this.List = res.data
                }else{
                    this.List = this.List.concat( res.data )
                }
            }else{
                this.handleError( res.message.msg )
            }
        })
    }

    handleFitlerState( state:string ){
        switch ( (state + "") ) {
            case "0":
                return "未支付"
            case "1":
                return "待接单"
            case "2":
                return "已接单"
            case "3":
                return "待确认"
            case "4":
                return "已完成"
            case "5":
                return "异常订单"
            case "6":
                return "订单已取消"
            default:
                return "啥也不是"
        }
    }

    handleFilterTime(time:string){
        let TimeArr = time.split(" ");
        return TimeArr[0].split("-")[1]
    }

    handleFitlerDay(time:string){
        let TimeArr = time.split(" ");
        return TimeArr[0].split("-")[2]
    }

    handleError( data:string ){
        let str = ""
        if ( data.length ){
            str = data
        }else{
            str = "网络繁忙..."
        }
        Notify({
            message: str,
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });
    }

    @Watch("UpData",{deep:true})
    ChangeUpData(newVal:AllHelpUpData){
        HelpStore.SetHelpMyUpData(newVal)
    }

    @Watch("List")
    ChangeList(newVal:Array<MyHelpItem>){
        HelpStore.SetHelpMyList(newVal)
    }
}
